<template>
    <div :id="'container-' + device.getDeviceId() + sourceToken + dataChannel" style="background-color: black">
        <div v-if="buffering === true" class="w-100 h-100 d-flex justify-content-center align-items-center"
             style=" z-index: 1; position: absolute">
            <Ellipsis width="10%"/>
        </div>
        <div :id="'stream-' + device.getDeviceId() + sourceToken + dataChannel" ref="stream"/>
    </div>
</template>

<script>
    import Vue from "vue";
    import deviceStore from "@/store/deviceStore";
    import PubSub from "pubsub-js";
    import Ellipsis from "@/components/loading_icons/ellipsis.vue";

    export default {
        name: "stream",
        props: {
            archiveOnly: {
                type: Boolean,
                default: false
            },
            device: {
                type: Object
            },
            sourceToken: {
                type: String,
                default: '0'
            },
            dataChannel: {
                type: String,
                default: undefined
            },
            quality: {
                type: Number,
                default: deviceStore.getters.getQuality
            },
            isInfinityGrid: {
                type: Boolean,
                default: true
            }
        },
        components: {
            Ellipsis
        },
        data() {
            return {
                playerId: null,
                qualityPubSub: null,
                pubsubs: [],
                intervalGetDecodedVideo: null,
                buffering: false,
            }
        },
        async created() {
            await this.construct(this.quality);
            this.pubsubs.push(PubSub.subscribe('quality-change-' + this.playerId, (msg, data) => {
                this.changeQuality(data);
            }));
            this.pubsubs.push(PubSub.subscribe('displayDecodedVideo' + this.playerId, (msg, data) => {
                this.device.displayDecodedVideo(this.playerId, !this.isInfinityGrid);
            }));
            this.pubsubs.push(PubSub.subscribe('videoBuffering' + this.playerId, (msg, data) => {
                this.buffering = data;
            }));
            this.pubsubs.push(PubSub.subscribe('startPlaybackRequest' + this.playerId, (msg, data) => {
                // data = {request, isContinuous}
                this.constructPlayback(data);
            }));
        },
        async beforeDestroy() {
            await this.destruct();
            this.pubsubs.forEach(sub => {
                PubSub.unsubscribe(sub);
            });
            clearInterval(this.intervalGetDecodedVideo);
        },

        methods: {
            async wipeStream() {
                await this.destruct();
                let temp = document.getElementById('stream-' + this.playerId);
                if (temp) {
                    temp.innerHTML = '';
                }
            },
            async changeQuality(quality) {
                await this.wipeStream();
                await this.construct(quality, true);
            },
            async constructPlayback(data) {
                await this.wipeStream();
                await this.construct(undefined, undefined, data)
            },
            async construct(quality = 0, qualityChangeBoolean, playbackRequest) {
                if (this.playerId !== null) {
                    await this.destruct();
                }

                /* Create Jmux Player */
                this.playerId = this.device.createPlayer(this.device, quality, this.sourceToken, this.dataChannel, this.archiveOnly === true);

                /* Display Stream */
                Vue.nextTick().then(async function () {
                    if (document.getElementById("stream-" + this.playerId) !== null) {
                        await document.getElementById("stream-" + this.playerId).appendChild(this.device.createInstance(this.sourceToken, this.dataChannel));
                        this.device.resetVideoSize(this.playerId, qualityChangeBoolean === true, !this.isInfinityGrid);
                        if (this.archiveOnly === true) {
                            this.device.changeDraggable(this.device.getDeviceId() + this.sourceToken + this.dataChannel, true);
                        }
                        if (this.archiveOnly === false) {
                            if (playbackRequest === undefined) {
                                this.device.startPlayer(this.playerId);
                            } else {
                                this.device.startPlayback(this.playerId, playbackRequest.request, playbackRequest.isContinuous);
                            }
                        }
                    }
                    clearInterval(this.intervalGetDecodedVideo);
                    this.intervalGetDecodedVideo = setInterval(() => {
                        this.device.displayDecodedVideo(this.playerId, !this.isInfinityGrid);
                    }, 60)
                }.bind(this));
                this.$emit('madePlayer', this.playerId);
            },
            async destruct() {
                await this.device.destroyPlayer(this.playerId);
                this.playerId = null;
            },
        }
    }
</script>

<style>
video::-webkit-media-controls-play-button, video::-webkit-media-controls-pausebutton {
    display: none;
}
</style>